@font-face{
  font-family: "modulus-pro-regular";
  src: url('./fonts/Modulus-Pro/modulus-pro-regular.otf') format('truetype')
}

@font-face{
  font-family: "modulus-pro-black";
  src: url('./fonts/Modulus-Pro/modulus-pro-black.otf') format('truetype')
}

@font-face{
  font-family: "modulus-pro-semi-bold";
  src: url('./fonts/Modulus-Pro/modulus-pro-semi-bold.otf') format('truetype')
}

@font-face{
  font-family: "modulus-pro-bold";
  src: url('./fonts/Modulus-Pro/modulus-pro-bold.otf') format('truetype')
}

@font-face{
  font-family: "modulus-pro-light";
  src: url('./fonts/Modulus-Pro/modulus-pro-light.otf') format('truetype')
}

body{
  margin: 0;
}

h1,h2{
  font-family: 'modulus-pro-black';
}